@font-face {
  font-family: Starcruiser;
  src: url("../../assets/Fonts/starcruiser.otf");
}
@font-face {
  font-family: D-DIN;
  src: url("../../assets/Fonts/D-DIN.otf");
}

.partner-page-container {
  background-image: url("../../assets/partner-page-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.partner-page-header-text{
    font-size: 3rem;
    font-family: Starcruiser;
    color: #FFFF00;
}